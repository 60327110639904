import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20240925-improvement-01",
        datum: "25.09.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.VERBESSERTES_FEATURE,
        title: "Übersicht Selbstverwaltungslink",
        lizenz: [LizenzEnum.KURSE],
        content: [
            "Eine neue Anzeige unterhalb der Teilnahmeliste ermöglicht es nun, alle versendeten Selbstverwaltungslinks "
            + "inklusive des Versanddatums einzusehen.",
        ],
        aktions: [
            {
                type: AktionTypeEnum.BLOG,
                text: "Details im Blog",
                url: "https://info.hiorg-server.de/?p=10843",
            },
        ],
    },
    {
        id: "20240925-improvement-02",
        datum: "25.09.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.VERBESSERTES_FEATURE,
        title: "Mehrere Kurse gleichzeitig öffentlich freischalten",
        lizenz: [LizenzEnum.KURSE],
        content: [
            "Über die Mehrfachauswahl der Kursliste können mehrere Kurse gleichzeitig zur öffentlichen Kursanzeige "
            + "hinzugefügt oder entfernt werden.",
        ],
        aktions: [
            {
                type: AktionTypeEnum.BLOG,
                text: "Details im Blog",
                url: "https://info.hiorg-server.de/?p=10843",
            },
        ],
    },
    {
        id: "20240925-improvement-03",
        datum: "25.09.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.VERBESSERTES_FEATURE,
        title: "Kleine Verbesserungen",
        lizenz: [LizenzEnum.KURSE],
        content: ["Textanpassung im Dialogfeld \"Kurs löschen\" (HiOrg-Server KURSE)"],
        aktions: [],
    },
];

export {
    insights,
};
