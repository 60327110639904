import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20241011-erechnung",
        datum: "11.10.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.INFO,
        title: "Die E-Rechnung kommt",
        lizenz: [LizenzEnum.PRO, LizenzEnum.KURSE],
        content: [
            "Zum Jahr 2025 gibt es neue Vorschriften bezügl. Rechnungen im B2B-Bereich. Wir informieren euch über die "
            + "Änderungen und welche Unterstützung innerhalb von HiOrg-Server geplant ist.",
        ],
        aktions: [
            {
                type: AktionTypeEnum.BLOG,
                text: "Details im Blog",
                url: "https://info.hiorg-server.de/?p=10870",
            },
            {
                type: AktionTypeEnum.FORUM,
                text: "Möglichkeit zum Austausch im HiOrg-Server Forum",
                url: "https://forum.hiorg-server.de/t/die-e-rechnung-kommt/2645",
            },
        ],
    },
];

export {
    insights,
};
