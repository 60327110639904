import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20250410-erechnung",
        datum: "10.04.2025",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.FEATURE,
        title: "Funktionen zur Erstellung von E-Rechnungen verfügbar",
        lizenz: [LizenzEnum.PRO, LizenzEnum.KURSE],
        content: [
            "Die E-Rechnung ist nicht einfach nur eine digitale Rechnung als PDF."
            + " Sie enthält strukturierte, maschinenlesbare Daten, die von Buchhaltungssystemen direkt verarbeitet werden können."
            + " Das macht den Rechnungsprozess effizienter, reduziert Fehler und sorgt für mehr Transparenz.",
            "Zur Nutzung müsst ihr eure Stammdaten erweitern (System → Einstellungen → Rechnungen)."
            + " Zusätzlich gibt es neue Rechnungsvorlagen und entsprechende Variablen dafür.",
        ],
        aktions: [
            {
                type: AktionTypeEnum.BLOG,
                text: "Details im Blog",
                url: "https://info.hiorg-server.de/?p=10990",
            },
            {
                type: AktionTypeEnum.LINK,
                text: "Hilfe findet ihr auch in unseren FAQ zur E-Rechnung & Steuersätzen",
                    url: "https://info.hiorg-server.de/faq-e-rechnung",
            },
            {
                type: AktionTypeEnum.FORUM,
                text: "Möglichkeit zum Austausch im HiOrg-Server Forum",
                url: "https://forum.hiorg-server.de/t/e-rechnung-in-hiorg-server/2722",
            },
        ],
    },
];

export {
    insights,
};
