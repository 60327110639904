import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20250114-qseh",
        datum: "14.01.2025",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.VERBESSERTES_FEATURE,
        title: "Update <i>meineQSEH</i>-Schnittstelle",
        lizenz: [LizenzEnum.KURSE],
        content: [
            "Ab 15.01.2025 ändern und erweiterten sich bei der QSEH die Bezeichnungen für die Lehrgangsmeldungen,"
            + " v.&#x202f;a. für die Aus- und Fortbildung von Lehrkräften.",
            "👉 ANPASSUNGEN NÖTIG<br>"
            + "Kurstypen, die zur Meldung bei <i>meineQSEH</i> markiert sind, werden automatisch ins neue Schema"
            + " überführt und angelegte Kurse angepasst. Die Aus- und Fortbildungen für Lehrkräfte werden aufgesplittet,"
            + " was eine Nachbearbeitung / Neuanlage durch euch erfordert.",
            "⚠️ ANZEIGE MELDESTATUS<br>"
            + "Während der Umstellung kann es vorkommen, dass der Meldestatus von Kursen im HiOrg-Server vorübergehend"
            + " nicht korrekt angezeigt wird. Sollte es am 16.&nbsp;Januar&nbsp;2025 weiter zu Fehlermeldungen"
            + " kommen, so meldet euch bitte bei uns.",
        ],
        aktions: [
            {
                type: AktionTypeEnum.BLOG,
                text: "Details im Blog",
                url: "https://info.hiorg-server.de/?p=10981",
            },
        ],
    },
];

export {
    insights,
};
