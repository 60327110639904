import type {Insight} from "../../../models/api/insight/Insight";
import {insights as insights20250114} from "./20250114";
import {insights as insights20250318} from "./20250318";
import {insights as insights20250410} from "./20250410";

const insights: Array<Insight> = [
    ...insights20250410,
    ...insights20250318,
    ...insights20250114,
];

export {
    insights,
};
