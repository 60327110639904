import {AktionTypeEnum} from "../../models/api/insight/AktionTypeEnum";
import type {FaIconType} from "../../models/api/insight/FaIconType";
import {InsightTypeEnum} from "../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../models/api/insight/KategorieEnum";

const iconKategorieMapper = (kategorie: KategorieEnum): FaIconType => {
    switch (kategorie) {
        case KategorieEnum.DESKTOP:
            return {icon: "fa-light fa-desktop", farbe: "tw:text-hiorg-teal-500"};
        case KategorieEnum.MOBILGERAETE_APP:
            return {icon: "fa-light fa-mobile-screen-button", farbe: "tw:text-hiorg-teal-500"};
        case KategorieEnum.COMMUNITY:
            return {icon: "fa-light fa-user-group", farbe: "tw:text-hiorg-teal-500"};
        default:
            return {icon: "", farbe: ""};
    }
};

const iconInsightTypeMapper = (insightType: InsightTypeEnum): FaIconType => {
    switch (insightType) {
        case InsightTypeEnum.BUGFIX:
            return {icon: "fa-light fa-bug", farbe: "tw:text-hiorg-blau-500"};
        case InsightTypeEnum.FEATURE:
            return {icon: "fa-light fa-sparkles", farbe: "tw:text-yellow-500"};
        case InsightTypeEnum.INFO:
            return {icon: "fa-light fa-circle-info", farbe: "tw:text-hiorg-blau-500"};
        case InsightTypeEnum.INTERVIEW:
            return {icon: "fa-light fa-user-group", farbe: "tw:text-hiorg-blau-500"};
        case InsightTypeEnum.VERBESSERTES_FEATURE:
            return {icon: "fa-light fa-wrench", farbe: "tw:text-hiorg-blau-500"};
        default:
            return {icon: "", farbe: ""};
    }
};

const iconAktionTypeMapper = (aktionType: AktionTypeEnum): FaIconType => {
    switch (aktionType) {
        case AktionTypeEnum.BLOG:
            return {icon: "fa-solid fa-blog", farbe: "tw:text-rot-500"};
        case AktionTypeEnum.FORUM:
            return {icon: "fa-regular fa-messages", farbe: "tw:text-rot-500"};
        case AktionTypeEnum.LINK:
        case AktionTypeEnum.NACHRICHT:
        case AktionTypeEnum.TYPEBOT:
            return {icon: "fa-light fa-arrow-up-right-from-square", farbe: "tw:text-rot-500"};
        case AktionTypeEnum.WIKI:
            return {icon: "fa-solid fa-book-open-reader", farbe: "tw:text-rot-500"};
        default:
            return {icon: "", farbe: ""};
    }
};

export {
    iconKategorieMapper,
    iconInsightTypeMapper,
    iconAktionTypeMapper,
};
